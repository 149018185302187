<template>
  <div>
    <v-card>
      <v-card-title>Account Information</v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Code"
                class="dt-text-field"
                outlined
                prepend-inner-icon="mdi-barcode-scan"
                hide-details
                v-model="form.code"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Id"
                class="dt-text-field"
                outlined
                prepend-inner-icon="mdi-id-card"
                v-model="form.dealer_id"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                type="email"
                label="Email"
                class="dt-text-field"
                outlined
                prepend-inner-icon="mdi-at"
                hide-details
                v-model="form.email"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Dealer Name"
                class="dt-text-field"
                outlined
                prepend-inner-icon="mdi-account-outline"
                hide-details
                v-model="form.name"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Address"
                class="dt-text-field"
                outlined
                prepend-inner-icon="mdi-map-marker"
                hide-details
                v-model="form.address_details"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Contact Information"
                class="dt-text-field"
                outlined
                prepend-inner-icon="mdi-card-account-phone-outline"
                hide-details
                v-model="form.contact_details"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-card class="mx-auto" outlined>
                <v-card-title class="pb-0">
                  <v-icon left>mdi-bank</v-icon>Bank Details</v-card-title
                >
                <v-card-text class="py-0">
                  <v-col cols="12">
                    <v-text-field
                      label="Bank"
                      class="dt-text-field"
                      outlined
                      hide-details
                      v-model="form.bank_details.bank"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Bank Account Name"
                      class="dt-text-field"
                      outlined
                      hide-details
                      v-model="form.bank_details.account_name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Bank Account Number"
                      class="dt-text-field"
                      outlined
                      hide-details
                      v-model="form.bank_details.account_number"
                    ></v-text-field>
                  </v-col>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Dealer PIC Name"
                class="dt-text-field"
                outlined
                prepend-inner-icon="mdi-account-outline"
                hide-details
                v-model="form.pic_name"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Dealer PIC Email"
                class="dt-text-field"
                outlined
                prepend-inner-icon="mdi-at"
                hide-details
                v-model="form.pic_email"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select
                v-if="zoneList"
                append-icon="mdi-chevron-down"
                :items="zoneList"
                item-text="name"
                item-value="id"
                label="Zone"
                outlined
                clearable
                hide-details
                v-model="form.zone_id"
                clear-icon="mdi-close-circle-outline"
                background-color="selects"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-select
                v-if="groupList"
                append-icon="mdi-chevron-down"
                :items="groupList"
                item-text="name"
                item-value="id"
                label="Group"
                outlined
                clearable
                hide-details
                v-model="form.group_id"
                clear-icon="mdi-close-circle-outline"
                background-color="selects"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-switch label="Status" v-model="form.status"></v-switch>
            </v-col>
          </v-row>
          <div class="d-flex justify-end mb-6" flat tile>
            <v-btn
              large
              exact
              color="green darken-1"
              class="ma-1 white--text"
              @click="saveOnly"
              :loading="loadingSave"
              :disabled="loadingSave || loadingSaveAndExit"
            >
              <v-icon left>mdi-content-save</v-icon>
              Save
            </v-btn>
            <v-btn
              large
              exact
              color="green"
              class="ma-1 white--text"
              @click="saveAndExit"
              :loading="loadingSaveAndExit"
              :disabled="loadingSave || loadingSaveAndExit"
            >
              <v-icon left>mdi-content-save</v-icon>
              Save and Exit
            </v-btn>
            <v-btn
              :disabled="loadingSave || loadingSaveAndExit"
              large
              exact
              color="warning"
              class="ma-1 white--text"
              :to="{ name: 'dealers-account-maintenance.dealers.index' }"
            >
              <v-icon left>mdi-close</v-icon>
              Cancel
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    details: {
      required: false,
      type: Object,
      default() {
        return {
          name: null,
          code: null,
          email: null,
          address_details: null,
          contact_details: null,
          bank_details: {
            bank: null,
            account_number: null,
            account_name: null,
          },
          pic_name: null,
          pic_email: null,
          status: false,
          zone_id: null,
          group_id: null,
        };
      },
    },
  },
  data() {
    return {
      valid: true,
      loadingSave: false,
      loadingSaveAndExit: false,
      form: {
        name: null,
        code: null,
        email: null,
        address_details: null,
        contact_details: null,
        bank_details: {
          bank: null,
          account_number: null,
          account_name: null,
        },
        pic_name: null,
        pic_email: null,
        status: false,
        zone_id: null,
        group_id: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      zoneList: "zones/GET_ZONES",
      groupList: "dealerGroups/GET_GROUPS",
    }),
  },
  mounted() {
    if (null !== this.details.name) {
      this.form.name = this.details.name;
      this.form.code = this.details.code;
      this.form.email = this.details.email;
      this.form.address_details = this.details.address_details;
      this.form.contact_details = this.details.contact_details;
      this.form.dealer_id = this.details.dealer_id;
      if (this.details.bank_details != null) {
        this.form.bank_details.bank = this.details.bank_details.bank;
        this.form.bank_details.account_number = this.details.bank_details.account_number;
        this.form.bank_details.account_name = this.details.bank_details.account_name;
      }
      this.form.pic_name = this.details.pic_name;
      this.form.pic_email = this.details.pic_email;
      if (this.details.dealer_zone != null) {
        this.form.zone_id = this.details.dealer_zone.id;
      }
      if (this.details.dealer_group != null) {
        this.form.group_id = this.details.dealer_group.id;
      }
      this.form.status = this.details.status == 1 ? true : false;
    }

    this.getZones();
    this.getGroups();
  },
  methods: {
    ...mapActions({
      getZones: "zones/getZones",
      getGroups: "dealerGroups/getGroups",
      save: "dealers/save",
    }),
    async submit() {
      var data = {
        name: this.form.name,
        code: this.form.code,
        email: this.form.email,
        address: this.form.address,
        contact_details: this.form.contact_details,
        bank_details: {
          bank: this.form.bank_details.bank,
          account_number: this.form.bank_details.account_number,
          account_name: this.form.bank_details.account_name,
        },
        pic_name: this.form.pic_name,
        pic_email: this.form.pic_email,
        status: this.form.status,
        zone_id: this.form.zone_id,
        group_id: this.form.group_id,
        dealer_id: this.form.dealer_id,
      };
      

      const id = this.$route.params.id ? this.$route.params.id : null;

      await this.save({
        id,
        data,
      }).then((data) => {
        this.loadingSave = false;
        this.loadingSaveAndExit = false;
      });
    },
    async saveOnly() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.loadingSave = true;

      await this.submit();

      if (!this.$route.params.id) {
        this.$refs.form.reset();
      }
    },
    async saveAndExit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.loadingSaveAndExit = true;

      await this.submit();

      this.$router.push({ name: "dealers-account-maintenance.dealers.index" });
    },
  },
};
</script>

<style></style>
